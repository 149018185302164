import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import APIs from "../../apis/api-design";
import axiosClient from "../../apis/axios-client";
import ChildPage from "../../components/child-page";
import PostItem from "../../components/post-card";
import { appLoading, getImageUrl } from "../../helpers/utils";

export default function NewsPage() {
  const { t } = useTranslation();
  const [data, setData] = useState<any>();
  const [bannerData, setBannerData] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      appLoading.show();
      await Promise.all([
        axiosClient.get(`${APIs.posts}&sort=createdAt`),
        axiosClient.get(APIs.newsPage),
      ])
        .then((val) => {
          const _data = val[0];
          const _bannerData = val[1];
          _data.data && setData(_data.data);
          _bannerData.data && setBannerData(_bannerData.data.attributes);
        })
        .finally(() => {
          appLoading.dismiss();
        });
    }

    fetchData();
  }, []);

  return bannerData ? (
    <ChildPage
      bannerImage={getImageUrl(bannerData?.Banner_Image)[0]}
      bannerTitle1={bannerData?.Title}
      boldTitle1
      bannerSubTitle={bannerData?.Descriptions}
    >
      <div className="news-page">
        <div className="container">
          <Row gutter={[16, 16]}>
            {data &&
              data.map((item: any, index: number) => (
                <Col key={index} span={24} md={8} lg={6}>
                  <PostItem
                    imageUrl={getImageUrl(item.attributes.Thumbnail)[0]}
                    title={item.attributes.Title}
                    content={item.attributes.Content}
                    date={item.attributes.createdAt}
                    url={item.attributes.url}
                  />
                </Col>
              ))}
          </Row>
        </div>
      </div>
    </ChildPage>
  ) : null;
}
