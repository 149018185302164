import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { t } from "i18next";
import { useEffect, useState } from "react";
import ReactImageGallery from "react-image-gallery";
import ReactMarkdown from "react-markdown";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import APIs from "../../apis/api-design";
import axiosClient from "../../apis/axios-client";
import AppTitle from "../../components/app-title";
import ChildPage from "../../components/child-page";
import {
  appLoading,
  getImageUrl,
  getProductPageTitle,
} from "../../helpers/utils";
import ProductItem from "./product-item";

export default function ProductDetails() {
  const location = useLocation();
  const [orderForm] = Form.useForm();
  const [data, setData] = useState<any>();
  const [relatedData, setRelatedData] = useState<any>();
  const [imageList, setImageList] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isModalConfirmVisible, setIsModalConfirmVisible] =
    useState<boolean>(false);
  const [confirmOrder, setConfirmOrder] = useState<any[]>([]);
  const [bannerData, setBannerData] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      appLoading.show();
      const _path = location.pathname.split("/");
      const _productKey = _path[_path.length - 1];
      const _categoryKey = _path[_path.length - 2];

      await Promise.all([
        axiosClient.get(`${APIs.products}&filters[url][$eq]=${_productKey}`),
        axiosClient.get(
          `${APIs.products}&sort=createdAt&filters[category][key]=${_categoryKey}&filters[url][$ne]=${_productKey}&&pagination[limit]=4`
        ),
        axiosClient.get(APIs.productPage),
      ])
        .then((val) => {
          const _data = val[0];
          const _relatedData = val[1];
          const _bannerData = val[2];
          _bannerData.data && setBannerData(_bannerData.data.attributes);

          if (_data.data && _data.data[0]) {
            const _images = getImageUrl(_data.data[0].attributes.Images);
            const _imageList = _images.map((image: string, index: number) => {
              return {
                original: image,
                thumbnail: image,
              };
            });
            setData(_data.data[0].attributes);
            _imageList && setImageList(_imageList);
            _relatedData.data && setRelatedData(_relatedData.data);
          }
        })
        .finally(() => {
          appLoading.dismiss();
        });
    }

    fetchData();
  }, [location]);

  const goToConfirm = () => {
    orderForm
      .validateFields()
      .then((el) => {
        appLoading.show();
        const { name, phone, email, amount, address } = el;
        const { Name, Price_Cost } = data;
        const _confirmOrder = [
          {
            label: t("productName"),
            text: Name,
          },
          {
            label: t("amount"),
            text: amount,
          },
          {
            label: t("total"),
            text: parseInt(Price_Cost) * amount,
          },
          {
            label: t("receiveName"),
            text: name,
          },
          {
            label: t("phoneNumber"),
            text: phone,
          },
          {
            label: t("email"),
            text: email,
          },
          {
            label: t("address"),
            text: address,
          },
        ];
        setConfirmOrder(_confirmOrder);
        setIsModalVisible(false);
        setIsModalConfirmVisible(true);
        appLoading.dismiss();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onOrder = async () => {
    appLoading.show();
    const { name, phone, email, amount, address } = orderForm.getFieldsValue();
    const { Name, Price_Cost } = data;
    const _body = {
      data: {
        Product_ID: data.url,
        Product_Name: Name,
        Amount: amount,
        Price: (parseInt(Price_Cost) * amount).toString(),
        Name: name,
        Phone_Number: phone,
        Email: email,
        Address: address,
      },
    };
    try {
      await axiosClient.post(APIs.orderList, _body);
      notification["success"]({
        message: "Đặt hàng thành công",
        description:
          "Đơn hàng của bạn đã được gửi thành công. Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất. Xin cảm ơn!",
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Đặt hàng thất bại",
        description:
          "Đơn hàng của bạn chưa được gửi. Vui lòng thử lại sau ít phút hoặc liên hệ bộ phận hỗ trợ. Xin cảm ơn!",
      });
    }
    orderForm.resetFields();
    setIsModalConfirmVisible(false);
    appLoading.dismiss();
  };

  const handleCancel = () => {
    orderForm.resetFields();
    setIsModalVisible(false);
    setIsModalConfirmVisible(false);
  };

  return (
    <ChildPage
      bannerImage={getImageUrl(bannerData?.Banner_Image)[0]}
      bannerTitle1={bannerData?.Title}
      boldTitle1
      bannerSubTitle={bannerData?.Descriptions}
    >
      {data && (
        <div className="product-details">
          <div className="container">
            <Row gutter={[64, 32]}>
              <Col span={24} md={12}>
                <div className="product-images">
                  {imageList && <ReactImageGallery items={imageList} />}
                </div>
              </Col>
              <Col span={24} md={12}>
                <div className="product-info">
                  <Typography.Title level={2} className="bold">
                    {data.Name}
                  </Typography.Title>
                  <Typography.Text className="category-label">
                    {"Danh mục: " +
                      getProductPageTitle(
                        `${location.pathname.replace(`/${data.url}`, "")}`
                      )}
                  </Typography.Text>
                  <Typography.Title level={4} className="bold">
                    <span className="cost-price">
                      <NumberFormat
                        value={data.Price_Cost}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={"đ"}
                      />
                    </span>
                    {data.Price_Sale && (
                      <>
                        {" - "}
                        <span className="sell-price red lineThrough">
                          <NumberFormat
                            value={data.Price_Sale}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={"đ"}
                          />
                        </span>
                      </>
                    )}
                  </Typography.Title>
                  <Typography.Text className="descriptions-text">
                    {data.Descriptions}
                  </Typography.Text>
                  <Button onClick={showModal} className="app-button revert">
                    Đặt hàng
                  </Button>
                </div>
              </Col>
            </Row>
            <div className="details-info">
              <Typography.Title level={3} className="bold">
                Chi tiết sản phẩm
              </Typography.Title>
              <Typography.Paragraph className="post-text">
                <span
                  dangerouslySetInnerHTML={{ __html: data.Details_Infomation }}
                />
              </Typography.Paragraph>
            </div>
            <div className="related-products">
              <AppTitle title1="Các sản phẩm" title2="liên quan" boldTitle2 />
              <div className="product-group">
                <Row gutter={[16, 16]}>
                  {relatedData &&
                    relatedData.map((item: any, index: number) => (
                      <Col key={index} span={24} sm={12} md={8} xl={6}>
                        <ProductItem isRelated data={item.attributes} />
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          </div>
          <Modal
            maskClosable={false}
            className="order-product-modal"
            title={t("order")}
            visible={isModalVisible}
            onOk={goToConfirm}
            onCancel={handleCancel}
            cancelText={t("close")}
            okText={t("order")}
            width="800px"
          >
            <div className="order-form">
              <Form
                form={orderForm}
                onFinish={goToConfirm}
                className="subscribe-form"
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col span={24} md={12}>
                    <Form.Item
                      name="name"
                      label={t("name")}
                      rules={[
                        {
                          required: true,
                          message: t("requiredField"),
                        },
                      ]}
                    >
                      <Input type="text" />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      name="phone"
                      label={t("phoneNumber")}
                      rules={[
                        {
                          required: true,
                          message: t("requiredField"),
                        },
                      ]}
                    >
                      <Input type="tel" />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      name="email"
                      label={t("email")}
                      rules={[
                        {
                          type: "email",
                          message: t("correctEmail"),
                        },
                      ]}
                    >
                      <Input type="email" />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      name="amount"
                      label={t("amount")}
                      rules={[
                        {
                          required: true,
                          message: t("requiredField"),
                        },
                      ]}
                    >
                      <Input type="number" min={1} max={99} maxLength={2} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="address"
                      label={t("address")}
                      rules={[
                        {
                          required: true,
                          message: t("requiredField"),
                        },
                      ]}
                    >
                      <TextArea className="text-area" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
          <Modal
            maskClosable={false}
            className="order-product-modal"
            title={t("confirmOrder")}
            visible={isModalConfirmVisible}
            onOk={onOrder}
            onCancel={handleCancel}
            cancelText={t("close")}
            okText={t("confirm")}
          >
            {confirmOrder.map((item, index) => (
              <Row key={index} gutter={[16, 16]}>
                <Col span={24} md={8}>
                  <Typography.Paragraph ellipsis={{ rows: 2 }}>
                    {item.label}:
                  </Typography.Paragraph>
                </Col>
                <Col span={24} md={16}>
                  <Typography.Paragraph
                    className={`semiBold ${
                      index === 4 || index === 6 ? "red" : ""
                    }`}
                    ellipsis={{ rows: 2 }}
                  >
                    {"  "}
                    {index === 2 ? (
                      <NumberFormat
                        className="red bold"
                        value={item.text}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={"đ"}
                      />
                    ) : (
                      item.text
                    )}
                  </Typography.Paragraph>
                </Col>
              </Row>
            ))}
          </Modal>
        </div>
      )}
    </ChildPage>
  );
}
