import { Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import APIs from "../../apis/api-design";
import axiosClient from "../../apis/axios-client";
import ChildPage from "../../components/child-page";
import Page404 from "../../components/page-404";
import { appLoading, getImageUrl } from "../../helpers/utils";

export default function AboutUs() {
  const [data, setData] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      appLoading.show();
      const _data = await axiosClient.get(APIs.aboutUs);
      _data.data && setData(_data.data.attributes);
      appLoading.dismiss();
    }

    fetchData();
  }, []);

  return data ? (
    <ChildPage
      bannerTitle1={data?.Title}
      boldTitle1
      bannerImage={getImageUrl(data?.Banner_Image)[0]}
      bannerSubTitle={data?.Descriptions}
    >
      <div className="about-page">
        <div className="container">
          <Row gutter={[16, 32]}>
            <Col span={24} md={12}>
              <div className="about-content">
                <Typography.Text>
                  <span dangerouslySetInnerHTML={{ __html: data.Content }} />
                </Typography.Text>
              </div>
            </Col>
            <Col span={24} md={12}>
              <div
                className="about-bg"
                style={{
                  backgroundImage: `url(${getImageUrl(data.Content_Image)[0]})`,
                }}
              ></div>
            </Col>
          </Row>
        </div>
      </div>
    </ChildPage>
  ) : (
    <Page404 />
  );
}
