import { Spin } from "antd";

function AppLoading() {
  return (
    <div id="app-loading" className="dNone app-loading">
      <Spin size="large" />
    </div>
  );
}

export default AppLoading;
