import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import APIs from "../../apis/api-design";
import axiosClient from "../../apis/axios-client";
import ChildPage from "../../components/child-page";
import { appLoading, getImageUrl } from "../../helpers/utils";
import { Button, Typography } from "antd";

export default function PriceList() {
  const [data, setData] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      appLoading.show();
      const _data = await axiosClient.get(APIs.priceList);
      _data.data && setData(_data.data.attributes);
      appLoading.dismiss();
    }

    fetchData();
  }, []);

  return data ? (
    <ChildPage
      bannerImage={getImageUrl(data?.Banner_Image)[0]}
      bannerTitle1={data?.Title}
      boldTitle1
      bannerSubTitle={data?.Descriptions}
    >
      <div className="new-page">
        <div className="container">
          <Typography.Paragraph className="post-text">
            <span dangerouslySetInnerHTML={{ __html: data.Content }} />
          </Typography.Paragraph>
        </div>
      </div>
    </ChildPage>
  ) : null;
}
