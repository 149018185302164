import { Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import APIs from "../../apis/api-design";
import axiosClient from "../../apis/axios-client";
import AppTitle from "../../components/app-title";
import ChildPage from "../../components/child-page";
import PostItem from "../../components/post-card";
import { appLoading, getImageUrl } from "../../helpers/utils";

export default function NewsDetails() {
  const location = useLocation();
  const [data, setData] = useState<any>();
  const [dataRelated, setDataRelated] = useState<any>();
  const [bannerData, setBannerData] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      appLoading.show();
      const _path = location.pathname.split("/");
      const _postUrl = _path[_path.length - 1];
      await Promise.all([
        axiosClient.get(`${APIs.posts}&filters[url][$eq]=${_postUrl}`),
        axiosClient.get(
          `${APIs.posts}&sort=createdAt&filters[url][$ne]=${_postUrl}&pagination[limit]=4`
        ),
        axiosClient.get(APIs.newsPage),
      ])
        .then((val) => {
          console.log(val[0].data[0].attributes.Content)
          const _data = val[0];
          const _dataRelated = val[1];
          _data.data && setData(_data.data[0].attributes);
          _dataRelated.data && setDataRelated(_dataRelated.data);
          const _bannerData = val[2];
          _bannerData.data && setBannerData(_bannerData.data.attributes);
        })
        .finally(() => {
          appLoading.dismiss();
        });
    }

    fetchData();
  }, [location]);

  return bannerData ? (
    <ChildPage
      bannerImage={getImageUrl(bannerData?.Banner_Image)[0]}
      bannerTitle1={bannerData?.Title}
      boldTitle1
      bannerSubTitle={bannerData?.Descriptions}
    >
      <div className="container">
        {data && (
          <div className="news-details">
            <Typography.Title level={3}>{data.Title}</Typography.Title>
            <Typography.Paragraph className="quote-text">
              {data.Quote}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <span
                className="news-post-content"
                dangerouslySetInnerHTML={{ __html: data.Content }}
              />
            </Typography.Paragraph>
            <div className="post-related">
              <AppTitle title1="Các bài viết" title2="liên quan" boldTitle2 />
              <Row gutter={[16, 16]}>
                {dataRelated &&
                  dataRelated.map((item: any, index: number) => (
                    <Col key={index} span={24} md={8} lg={6}>
                      <PostItem
                        imageUrl={getImageUrl(item.attributes.Thumbnail)[0]}
                        title={item.attributes.Title}
                        content={item.attributes.Content}
                        date={item.attributes.createdAt}
                        url={`/news/${item.attributes.url}`}
                      />
                    </Col>
                  ))}
              </Row>
            </div>
          </div>
        )}
      </div>
    </ChildPage>
  ) : null;
}
