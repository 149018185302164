import { Typography } from "antd";
import StyledVariables from "../helpers/materials/_variables";

type AppTitleType = {
  title1: string;
  title2?: string;
  boldTitle1?: boolean;
  boldTitle2?: boolean;
  breakTitle?: boolean;
  subTitle?: string;
};

export default function AppTitle(props: AppTitleType) {
  const boldStyle = {
    fontWeight: "bold",
    color: StyledVariables.colors.black,
  };

  return (
    <div className="app-title">
      <Typography.Title>
        {props.title1 && (
          <span style={props.boldTitle1 ? boldStyle : {}}>{props.title1}</span>
        )}
        {props.breakTitle && <br />}
        {props.title2 && (
          <span style={props.boldTitle2 ? boldStyle : {}}>
            {" " + props.title2}
          </span>
        )}
      </Typography.Title>
      {props.subTitle && (
        <div className="app-title-sub">
          <Typography.Paragraph ellipsis={{ rows: 3 }}>
            {props.subTitle}
          </Typography.Paragraph>
        </div>
      )}
    </div>
  );
}
