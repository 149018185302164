import { MailOutlined, PhoneOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, notification, Row, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import APIs from "../apis/api-design";
import axiosClient from "../apis/axios-client";
import { appLoading, getImageUrl } from "../helpers/utils";
import AppTitle from "./app-title";
import Phone from "../assets/images/pinner/phone.png";
import Zalo from "../assets/images/pinner/zalo.png";
import Messenger from "../assets/images/pinner/messenger.png";

export default function AppFooter() {
  const { t } = useTranslation();
  const [contactForm] = Form.useForm();
  const [subscribeForm] = Form.useForm();
  const [data, setData] = useState<any>();
  const formRender = [
    {
      label: t("name"),
      name: "name",
    },
    {
      label: t("email"),
      name: "email",
    },
    {
      label: t("comments"),
      name: "comments",
    },
  ];

  useEffect(() => {
    async function fetchData() {
      appLoading.show();
      const _data = await axiosClient.get(APIs.footer);
      _data.data && setData(_data.data.attributes);
      appLoading.dismiss();
    }
    fetchData();
  }, []);

  const onFinish = async (values: any) => {
    appLoading.show();
    const { name, email, comments } = values;
    const body = {
      data: { Name: name, Email: email, Comment: comments },
    };
    await axiosClient.post(APIs.contactForm, body);
    contactForm.resetFields();
    appLoading.dismiss();
    notification["success"]({
      message: data.Alert_Contact_Form_Success.Message,
      description: data.Alert_Contact_Form_Success.Descriptions,
    });
  };

  const onSubscribe = async (values: any) => {
    appLoading.show();
    const { email } = values;
    const body = {
      data: { Email: email },
    };

    try {
      await axiosClient.post(APIs.emailReceive, body);
      notification["success"]({
        message: data.Alert_Subscribe_Form_Success.Message,
        description: data.Alert_Subscribe_Form_Success.Descriptions,
      });
    } catch (error) {
      notification["error"]({
        message: data.Alert_Subscribe_Form_Failed.Message,
        description: data.Alert_Subscribe_Form_Failed.Descriptions,
      });
    }
    subscribeForm.resetFields();
    appLoading.dismiss();
  };

  return data ? (
    <>
      <div className="contact-pinner">
        <Typography.Link
          href={`tel:+84${data.Phone_Number}`}
          className="pinner-item"
        >
          <img src={Phone} alt="" />
        </Typography.Link>
        <Typography.Link
          href={`https://zalo.me/0${data.Phone_Number}`}
          target="_blank"
          className="pinner-item"
        >
          <img src={Zalo} alt="" />
        </Typography.Link>
        <Typography.Link
          href={`https://m.me/${data.Messenger_ID}`}
          target="_blank"
          className="pinner-item"
        >
          <img src={Messenger} alt="" />
        </Typography.Link>
      </div>
      <div className="app-footer">
        <div className="contact-group">
          <div className="container">
            <AppTitle
              breakTitle={data.Contact_Title.Break_Word}
              title1={data.Contact_Title.Title_1}
              title2={data.Contact_Title.Title_2}
              boldTitle1={data.Contact_Title.Bold_Title_1}
              boldTitle2={data.Contact_Title.Bold_Title_2}
              subTitle={data.Contact_Title.Descriptions}
            />
            <div className="contact-form">
              <Form form={contactForm} onFinish={onFinish}>
                <Row gutter={[32, 16]}>
                  {formRender.map((item, index) => (
                    <Col key={index} span={24} md={index < 2 ? 12 : 24}>
                      <Form.Item>
                        {index < 2 && (
                          <Form.Item
                            name={item.name}
                            rules={[
                              {
                                required: true,
                                message: t("requiredField"),
                              },
                            ]}
                          >
                            <Input
                              type={index === 0 ? "text" : "email"}
                              placeholder={item.label}
                              bordered={false}
                            />
                          </Form.Item>
                        )}
                        {index === 2 && (
                          <Form.Item
                            name={item.name}
                            rules={[
                              {
                                required: true,
                                message: t("requiredField"),
                              },
                            ]}
                          >
                            <TextArea
                              className="text-area"
                              placeholder={item.label}
                              bordered={false}
                            />
                          </Form.Item>
                        )}
                      </Form.Item>
                    </Col>
                  ))}
                  <Col span={24}>
                    <Form.Item name="submit">
                      <Button htmlType="submit" className="app-button">
                        {t("submit")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
        <div className="footer-group">
          <div className="container">
            <Row gutter={32}>
              <Col xs={{ order: 2, span: 24 }} md={{ order: 1, span: 15 }}>
                <div className="footer-left">
                  <div className="left-top">
                    <div className="logo">
                      {data.Logo && (
                        <img src={getImageUrl(data.Logo)[0]} alt="" />
                      )}
                    </div>
                    <div className="infomation">
                      <Typography.Text className="info-item">
                        <SendOutlined className="info-icon" />
                        <span className="info-text">{data.Address}</span>
                      </Typography.Text>
                      <Typography.Text className="info-item">
                        <PhoneOutlined className="info-icon" />
                        <Typography.Link
                          href={`tel:+84${data.Phone_Number}`}
                          className="info-text"
                        >
                          <NumberFormat
                            value={data.Phone_Number}
                            displayType={"text"}
                            type="tel"
                            format={data.Format_Phone_Number}
                          />
                        </Typography.Link>
                      </Typography.Text>
                      <Typography.Text className="info-item">
                        <MailOutlined className="info-icon" />
                        <Typography.Link
                          href="mailto:cskh@eta-food.com.vn"
                          className="info-text"
                        >
                          {data.Email}
                        </Typography.Link>
                      </Typography.Text>
                    </div>
                  </div>
                  <div className="left-bottom">
                    <Typography.Text>
                      {data.Copyright + " Created by "}
                      <Typography.Link href="/">
                        Minh Khôi Solutions
                      </Typography.Link>
                    </Typography.Text>
                  </div>
                </div>
              </Col>
              <Col xs={{ order: 1, span: 24 }} md={{ order: 2, span: 9 }}>
                <div className="footer-right">
                  <Typography.Text className="get-letter">
                    {t("getLetter")}
                  </Typography.Text>
                  <Form
                    form={subscribeForm}
                    onFinish={onSubscribe}
                    className="subscribe-form"
                  >
                    <Row gutter={[32, 32]}>
                      <Col flex="auto">
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: t("requiredField"),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t("email")}
                            type="email"
                            bordered={false}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex="100px">
                        <Form.Item name="submit">
                          <Button
                            htmlType="submit"
                            className="app-button revert"
                          >
                            {t("subscribe")}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  ) : null;
}
