import { Button, Result } from "antd";
import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to={"/"}>
          <Button className="app-button">Về trang chủ</Button>
        </Link>
      }
    />
  );
}
