import { BackTop } from "antd";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppFooter from "../components/footer";
import AppHeader from "../components/header";
import Page404 from "../components/page-404";
import AboutUs from "../views/about/about";
import HomePage from "../views/home/home";
import NewsPage from "../views/news/news";
import NewsDetails from "../views/news/news-details";
import PriceList from "../views/price-list/price-list";
import ProductDetails from "../views/products/product-details";
import Products from "../views/products/products";
import Recruiment from "../views/recruiment/recruiment";

function AppRoutes() {
  return (
    <BrowserRouter>
      <AppHeader />
      <BackTop style={{ right: 30 }} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="product/:id" element={<Products />} />
        <Route path="product/all" element={<Products isAll />} />
        <Route path="product/:id/:key" element={<ProductDetails />} />
        <Route path="news" element={<NewsPage />} />
        <Route path="news/:id" element={<NewsDetails />} />
        <Route path="recruiment" element={<Recruiment />} />
        <Route path="price-table" element={<PriceList />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <AppFooter />
    </BrowserRouter>
  );
}

export default AppRoutes;
