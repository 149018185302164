import { Button, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type PostItemType = {
  imageUrl: string;
  title: string;
  content: string;
  date: Date;
  url: string;
};

export default function PostItem(props: PostItemType) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [postDate, setPostDate] = useState<string>("");
  const [postMonth, setPostMonth] = useState<string>("");

  useEffect(() => {
    const _date = new Date(props.date).getDate();
    const _month = new Date(props.date).toLocaleString("en-us", {
      month: "short",
    });
    const _newDate = ("0" + _date).slice(-2);
    setPostDate(_newDate);
    setPostMonth(_month);
  }, []);

  const goToPost = () => {
    navigate(`${props.url}`);
    window.scrollTo({ top: 0 });
  };

  return (
    <div className="post-item" onClick={goToPost}>
      <div className="post-image-container">
        <div
          className="post-image"
          style={{ backgroundImage: `url(${props.imageUrl})` }}
        ></div>
        <div className="post-date">
          <Typography.Paragraph>
            <span className="post-day">{postDate}</span>
            <br />
            <span className="post-month">{postMonth}</span>
          </Typography.Paragraph>
        </div>
      </div>
      <div className="post-content">
        <Typography.Paragraph className="post-title" ellipsis={{ rows: 2 }}>
          {props.title}
        </Typography.Paragraph>
        <Typography.Paragraph className="post-text" ellipsis={{ rows: 3 }}>
          <span dangerouslySetInnerHTML={{ __html: props.content }} />
        </Typography.Paragraph>
        <Button className="app-button revert mt12">{t("viewMore")}</Button>
      </div>
    </div>
  );
}
