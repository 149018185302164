import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import APIs from "../../apis/api-design";
import axiosClient from "../../apis/axios-client";
import AppTitle from "../../components/app-title";
import ChildPage from "../../components/child-page";
import {
  appLoading,
  getImageUrl,
  getProductPageTitle,
} from "../../helpers/utils";
import ProductItem from "./product-item";

type ProductsType = {
  isAll?: boolean;
};

export default function Products(props: ProductsType) {
  const { t } = useTranslation();
  const location = useLocation();
  const [data, setData] = useState<any>();
  const [bannerData, setBannerData] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      appLoading.show();
      const _categoryKey = location.pathname.replace("/product/", "");
      const _apiUrl = props.isAll
        ? APIs.products
        : `${APIs.products}&filters[category][key][$eq]=${_categoryKey}`;

      await Promise.all([
        axiosClient.get(_apiUrl),
        axiosClient.get(APIs.productPage),
      ])
        .then((val) => {
          const _data = val[0];
          const _bannerData = val[1];
          _data.data && setData(_data.data);
          _bannerData.data && setBannerData(_bannerData.data.attributes);
        })
        .finally(() => {
          appLoading.dismiss();
        });
    }

    fetchData();
  }, [location]);

  return (
    <ChildPage
      bannerImage={getImageUrl(bannerData?.Banner_Image)[0]}
      bannerTitle1={bannerData?.Title}
      boldTitle1
      bannerSubTitle={bannerData?.Descriptions}
    >
      <div className="container">
        <AppTitle
          title1={
            props.isAll
              ? "Tất cả sản phẩm"
              : getProductPageTitle(location.pathname)
          }
          boldTitle1
        />
        <div className="product-group">
          <Row gutter={[16, 16]}>
            {data &&
              data.map((item: any, index: number) => (
                <Col key={index} span={24} sm={12} md={8} xl={6}>
                  <ProductItem data={item.attributes} />
                </Col>
              ))}
          </Row>
        </div>
      </div>
    </ChildPage>
  );
}
