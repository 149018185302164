const APIs = {
  homeCarousel: "/home-carousel?populate=*",
  homePage: "/home-page?populate=*",
  menuBar: "/categories",
  products: "/products?populate=*",
  footer: "/footer?populate=*",
  posts: "/posts?populate=*",
  recruiment: "/recruiment?populate=*",
  priceList: "/prices-list?populate=*",
  newsPage: "/news-page?populate=*",
  productPage: "/product-page?populate=*",
  aboutUs: "/about-us?populate=*",
  contactForm: "/contact-forms?populate=*",
  emailReceive: "/email-receive-notifications?populate=*",
  orderList: "/order-lists",
};

export default APIs;
