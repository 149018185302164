import { CaretDownFilled } from "@ant-design/icons";

interface CustomMenuPropsRequired {
  label: string;
  key: string;
}

interface CustomMenuProps extends CustomMenuPropsRequired {
  icon?: JSX.Element;
  popupClassName?: string;
  children?: CustomMenuPropsRequired[];
}

export const menuLists: CustomMenuProps[] = [
  {
    label: "Trang chủ",
    key: "",
  },
  {
    label: "Về chúng tôi",
    key: "about",
  },
  {
    label: "Sản phẩm",
    key: "product",
    icon: <CaretDownFilled />,
    popupClassName: "header-submenu-list",
    children: [],
  },
  {
    label: "Tin tức",
    key: "news",
  },
  {
    label: "Bảng giá gạo",
    key: "price-table",
  },
  {
    label: "Tuyển đại lý",
    key: "recruiment",
  },
  // {
  //   label: "Liên hệ",
  //   key: "contact",
  // },
];
