import { Button, Typography } from "antd";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getImageUrl } from "../../helpers/utils";

type ProductItemType = {
  data: any;
  isRelated?: boolean;
};

export default function ProductItem(props: ProductItemType) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const onViewDetails = () => {
    const _path = props.isRelated
      ? `${location.pathname.split("/").slice(0, -1).join("/")}/${
          props.data.url
        }`
      : `${props.data.url}`;
    navigate(_path);
    window.scrollTo({ top: 0 });
  };

  return (
    <div onClick={onViewDetails} style={{cursor: "pointer"}} className="product-item">
      <div className="product-image">
        {props.data.Thumbnail && (
          <img src={getImageUrl(props.data.Thumbnail)[0]} alt="" />
        )}
      </div>
      <div className="product-content">
        <Typography.Paragraph className="content-title" ellipsis={{ rows: 2 }}>
          {props.data.Name}
        </Typography.Paragraph>
        <Typography.Paragraph
          className="content-sub-title"
          ellipsis={{ rows: 4 }}
        >
          <span dangerouslySetInnerHTML={{ __html: props.data.Descriptions }} />
        </Typography.Paragraph>
        <Typography.Paragraph className="content-price" ellipsis={{ rows: 2 }}>
          <span className="cost-price">
            <NumberFormat
              value={props.data.Price_Cost}
              displayType={"text"}
              thousandSeparator={true}
              suffix={"đ"}
            />
          </span>
          {props.data.Price_Sale && (
            <>
              {" - "}
              <span className="sell-price">
                <NumberFormat
                  value={props.data.Price_Sale}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix={"đ"}
                />
              </span>
            </>
          )}
        </Typography.Paragraph>
        <div className="product-button">
          <Button onClick={onViewDetails} className="app-button revert">
            {t("viewDetails")}
          </Button>
        </div>
      </div>
    </div>
  );
}
