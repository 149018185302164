import { Typography } from "antd";

type ChildPageType = {
  children: JSX.Element;
  bannerImage: string;
  bannerTitle1: string;
  bannerTitle2?: string;
  boldTitle1?: boolean;
  boldTitle2?: boolean;
  bannerSubTitle?: string;
};

export default function ChildPage(props: ChildPageType) {
  return (
    <div className="child-page">
      <div
        className="banner"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0,.3), rgba(0, 0, 0,.3)), url(${props.bannerImage})`,
        }}
      >
        <div className="banner-content">
          <Typography.Text>
            <span
              className={`small-banner-title ${props.boldTitle1 ? "bold" : ""}`}
            >
              {props.bannerTitle1}
            </span>
            {props.bannerTitle2 && (
              <>
                <br />
                <span
                  className={`bigger-banner-title ${
                    props.boldTitle2 ? "bold" : ""
                  }`}
                >
                  {props.bannerTitle2}
                </span>
              </>
            )}
          </Typography.Text>
          {props.bannerSubTitle && (
            <div className="banner-sub-title">
              <Typography.Text className="banner-sub-title">
                {props.bannerSubTitle}
              </Typography.Text>
            </div>
          )}
        </div>
      </div>
      <div className="child-content">{props.children}</div>
    </div>
  );
}
