import Cookies from "js-cookie";
import i18n from "../i18n";
import { menuLists } from "./constant";

const changeLanguage = () => {
  const currentLanguageCode = Cookies.get("i18next") || "en";
  const currentLanguage = currentLanguageCode === "vi" ? "en" : "vi";
  i18n.changeLanguage(currentLanguage);
};

const appLoading = {
  show: () => {
    document.getElementById("app-loading")?.classList.remove("dNone");
  },
  dismiss: () => {
    document.getElementById("app-loading")?.classList.add("dNone");
  },
};

const getProductPageTitle = (pathName: string) => {
  const menuItem = menuLists?.find((el) => el.key === "product");
  const productMenuItem = menuItem?.children?.find(
    (childEl) => `/${childEl.key}` === pathName
  );

  return productMenuItem?.label as string;
};

const getImageUrl = (image: any) => {
  const images: string[] = [];
  if (image?.data) {
    if (Array.isArray(image.data)) {
      image.data.forEach((_image: any) => {
        images.push(process.env.REACT_APP_API_ENDPOINT + _image.attributes.url);
      });
    } else {
      images.push(
        process.env.REACT_APP_API_ENDPOINT + image.data.attributes.url
      );
    }
    return images;
  }
  return [""];
};

export { changeLanguage, appLoading, getProductPageTitle, getImageUrl };
