import { Col, Menu, MenuProps, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import APIs from "../apis/api-design";
import axiosClient from "../apis/axios-client";
import Logo from "../assets/images/logo.png";
import { menuLists } from "../helpers/constant";
import { appLoading } from "../helpers/utils";

export default function AppHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const prevScrollY = useRef(0);
  const [current, setCurrent] = useState("");
  const [bgHeader, setBgHeader] = useState<number>(0);
  const [menuText, setMenuText] = useState<string>("rgba(255, 255, 255, 1)");
  const [hasData, setHasData] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      appLoading.show();
      const _menu = await axiosClient.get(APIs.menuBar);
      if (_menu.data) {
        const prodIndex = menuLists.findIndex((el) => el.key === "product");
        const _categories = _menu.data.map((val: any) => {
          return {
            label: val.attributes.Label,
            key: `product/${val.attributes.key}`,
          };
        });
        menuLists[prodIndex].children = _categories;
        setHasData(true);
      }
      appLoading.dismiss();
    }
    fetchData();
    window.addEventListener("scroll", setHeaderBg, { passive: true });
    function setHeaderBg() {
      const currentScrollY = window.scrollY;
      if (currentScrollY <= 255) {
        const textR = 255 - currentScrollY * (1 / (255 / (255 - 17)));
        const textG = 255 - currentScrollY * (1 / (255 / (255 - 95)));
        const textB = 255 - currentScrollY * (1 / (255 / (255 - 55)));
        setBgHeader(currentScrollY / 255);
        setMenuText(`rgba(${textR}, ${textG}, ${textB}, 1)`);
      } else if (bgHeader !== 1 && currentScrollY > 255) {
        setBgHeader(1);
        setMenuText(`rgba(17, 95, 55, 1)`);
      }
      prevScrollY.current = currentScrollY;
    }
    setHeaderBg();
    setCurrent(location.pathname.split("/")[1]);
    if (location.pathname[location.pathname.length - 1] === "/") {
      navigate(location.pathname.slice(0, -1));
    }
  }, [navigate]);

  const onClick: MenuProps["onClick"] = (e) => {
    const path = e.key === "home" ? "/" : e.key;
    navigate(path);
    setCurrent(e.key);
    window.scrollTo({ top: 0 });
  };

  return (
    <div
      className="app-header"
      style={{
        backgroundColor: `rgba(255, 255, 255, ${bgHeader})`,
        boxShadow: bgHeader === 1 ? "0 0 8px rgba(17, 95, 55, 0.5)" : "none",
      }}
    >
      <div className="container">
        <Row className="app-header-row">
          <Col span={4}>
            <div className="logo">
              <a href="./">
                <img src={Logo} alt="" />
              </a>
            </div>
          </Col>
          <Col span={20}>
            <div className="menu">
              {hasData && (
                <Menu
                  onClick={onClick}
                  selectedKeys={[current]}
                  mode="horizontal"
                  items={menuLists}
                  style={{
                    color: menuText,
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
