import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./context/app-context";
import AppRoutes from "./routes/app-routes";
import "./index.css";
import "./i18n";
import "./main.scss";
import "./App.less";
import { Spin } from "antd";
import AppLoading from "./components/app-loading";

const rootElement = document.getElementById("root");
const loadingMarkup = (
  <div style={{ display: "flex" }}>
    <Spin size="large" />
  </div>
);

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
      <AppLoading />
    </React.StrictMode>
  </Suspense>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
