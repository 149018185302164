import { Button, Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import AppCarousel from "../../components/app-carousel";
import AppTitle from "../../components/app-title";
import RiceImage from "../../assets/images/home-about.png";
import AboutBG from "../../assets/images/home-about-bg.png";
import Quatity1 from "../../assets/images/quatity_1.png";
import Quatity2 from "../../assets/images/quatity_2.png";
import Quatity3 from "../../assets/images/quatity_3.png";
import PostItem from "../../components/post-card";
import { useEffect, useState } from "react";
import { appLoading, getImageUrl } from "../../helpers/utils";
import APIs from "../../apis/api-design";
import axiosClient from "../../apis/axios-client";
import { useNavigate } from "react-router-dom";

export default function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [productSpecial, setProductSpecial] = useState<any>();
  const [productNew, setProductNew] = useState<any>();
  const [posts, setPosts] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      appLoading.show();
      await Promise.all([
        axiosClient.get(APIs.homePage),
        axiosClient.get(
          `${APIs.products}&filters[Special][$eq]=true&pagination[limit]=6`
        ),
        axiosClient.get(`${APIs.products}&sort=createdAt&pagination[limit]=6`),
        axiosClient.get(`${APIs.posts}&sort=createdAt&pagination[limit]=3`),
      ])
        .then((_data) => {
          const _homeData = _data[0];
          const _productSpecial = _data[1];
          const _productNew = _data[2];
          const _posts = _data[3];

          if (_homeData.data) {
            const _newData = _homeData.data.attributes;
            const _qualityBanner = _newData.Quality_Banner;
            _newData.Quality_Banner = [
              {
                Quality: _qualityBanner.Quality_1,
                Quality_Bold: _qualityBanner.Quality_Bold_1,
                imageUrl: Quatity3,
              },
              {
                Quality: _qualityBanner.Quality_2,
                Quality_Bold: _qualityBanner.Quality_Bold_2,
                imageUrl: Quatity1,
              },
              {
                Quality: _qualityBanner.Quality_3,
                Quality_Bold: _qualityBanner.Quality_Bold_3,
                imageUrl: Quatity2,
              },
            ];
            setData(_newData);
          }

          _productSpecial.data && setProductSpecial(_productSpecial.data);
          _productNew.data && setProductNew(_productNew.data);
          _posts.data && setPosts(_posts.data);
        })
        .finally(() => {
          appLoading.dismiss();
        });
    }
    fetchData();
  }, []);

  const goToDetails = (item: any) => {
    const _categoryKey = item.category.data.attributes.key;
    const _productKey = item.url;
    const _path = `/product/${_categoryKey}/${_productKey}`;
    navigate(_path);
    window.scrollTo({ top: 0 });
  };

  const goToAbout = () => {
    navigate("about");
    window.scrollTo({ top: 0 });
  };

  const goToProducts = (item?: any) => {
    const path = item ? item.url : "all";
    const categoryPath = item?.category.data.attributes.key;
    navigate(`product/${categoryPath}/${path}`);
    window.scrollTo({ top: 0 });
  };

  return data ? (
    <div className="home-page">
      <AppCarousel />
      <div className="container">
        <div className="special-product">
          <AppTitle
            breakTitle={data.Product_Hot_Title.Break_Word}
            title1={data.Product_Hot_Title.Title_1}
            title2={data.Product_Hot_Title.Title_2}
            boldTitle1={data.Product_Hot_Title.Bold_Title_1}
            boldTitle2={data.Product_Hot_Title.Bold_Title_2}
            subTitle={data.Product_Hot_Title.Descriptions}
          />
          <div className="product-item-group">
            <Row gutter={[16, 32]}>
              {productSpecial &&
                productSpecial.map((item: any, index: number) => (
                  <Col key={index} span={12} sm={8} md={4}>
                    <div
                      className="product-item"
                      onClick={() => goToDetails(item.attributes)}
                    >
                      <div className="product-item-image">
                        <div
                          className="img-item"
                          style={{
                            backgroundImage: `url(${getImageUrl(
                              item.attributes.Thumbnail
                            )})`,
                          }}
                        ></div>
                      </div>
                      <Typography.Paragraph
                        ellipsis={{ rows: 2 }}
                        className="product-item-title"
                      >
                        {item.attributes.Name}
                      </Typography.Paragraph>
                      <div className="border-bottom"></div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </div>
        <div className="text-animation">
          <Typography.Title>
            {t("forMostPeople1")}
            <br />
            {t("forMostPeople2")}
          </Typography.Title>
        </div>
        <div className="home-about">
          <div className="bg-title">
            <img src={AboutBG} alt="" />
          </div>
          <Row gutter={[16, 32]}>
            <Col span={24} md={8}>
              <div className="mt44">
                <AppTitle
                  breakTitle={data.About_Us_Title.Break_Word}
                  title1={data.About_Us_Title.Title_1}
                  title2={data.About_Us_Title.Title_2}
                  boldTitle1={data.About_Us_Title.Bold_Title_1}
                  boldTitle2={data.About_Us_Title.Bold_Title_2}
                />
              </div>
            </Col>
            <Col span={24} md={8}>
              <div className="home-about-image">
                <img src={RiceImage} alt="" />
              </div>
            </Col>
            <Col span={24} md={8}>
              <div className="home-about-content">
                <Typography.Paragraph ellipsis={{ rows: 6 }}>
                  {data.About_Us_Title.Descriptions}
                </Typography.Paragraph>
                <div className="view-more">
                  <Button
                    className="app-button revert mt12"
                    onClick={goToAbout}
                  >
                    {t("viewMore")}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="quality-show">
        <div className="container">
          {data.Quality_Banner.map((item: any, index: number) => (
            <div key={index} className="quality-item">
              <img src={item.imageUrl} alt="" />
              <Typography.Text>
                <span>{item.Quality}</span>
                <br />
                <span className="emphasize-item">{item.Quality_Bold}</span>
              </Typography.Text>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <div className="home-product">
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
              <AppTitle
                breakTitle={data.News_Product_Title.Break_Word}
                title1={data.News_Product_Title.Title_1}
                title2={data.News_Product_Title.Title_2}
                boldTitle1={data.News_Product_Title.Bold_Title_1}
                boldTitle2={data.News_Product_Title.Bold_Title_2}
                subTitle={data.News_Product_Title.Descriptions}
              />
              <Button
                onClick={() => goToProducts()}
                className="app-button revert"
              >
                {t("allProducts")}
              </Button>
            </Col>
            {productNew &&
              productNew.map((item: any, index: number) => (
                <Col key={index} span={24} md={6}>
                  <div
                    className="product-item"
                    onClick={() => goToProducts(item.attributes)}
                  >
                    <div
                      className="item-image"
                      style={{
                        backgroundImage: `url(${getImageUrl(
                          item.attributes.Thumbnail
                        )})`,
                      }}
                    >
                      <div className="item-text">
                        <Typography.Text>
                          {item.attributes.Name}
                        </Typography.Text>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </div>
      <div className="home-posts">
        <div className="container">
          <AppTitle
            breakTitle={data.Post_Title.Break_Word}
            title1={data.Post_Title.Title_1}
            title2={data.Post_Title.Title_2}
            boldTitle1={data.Post_Title.Bold_Title_1}
            boldTitle2={data.Post_Title.Bold_Title_2}
            subTitle={data.Post_Title.Descriptions}
          />
          <div className="post-group">
            <Row gutter={[32, 32]}>
              {posts &&
                posts.map((item: any, index: number) => (
                  <Col key={index} span={24} md={8}>
                    <PostItem
                      imageUrl={getImageUrl(item.attributes.Thumbnail)[0]}
                      title={item.attributes.Title}
                      content={item.attributes.Content}
                      date={item.attributes.createdAt}
                      url={`news/${item.attributes.url}`}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
