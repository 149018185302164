import { Button, Carousel, Typography } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIs from "../apis/api-design";
import axiosClient from "../apis/axios-client";
import { menuLists } from "../helpers/constant";
import { appLoading, getImageUrl } from "../helpers/utils";

export default function AppCarousel() {
  const navigate = useNavigate();
  const [data, setData] = useState<any>(null);
  const [carouselPicture, setCarouselPicture] = useState<string[]>([]);

  useEffect(() => {
    async function fetchData() {
      appLoading.show();
      const _data = await axiosClient.get(APIs.homeCarousel);
      if (_data.data) {
        const _newData = _data.data.attributes;
        setData(_newData);
        setCarouselPicture(getImageUrl(_newData.Images));
      }
      appLoading.dismiss();
    }

    fetchData();
  }, []);

  const goToProduct = () => {
    navigate("product/all");
    window.scrollTo({ top: 0 });
  };

  const CarouselContent = () => {
    return (
      data && (
        <div className="carousel-content">
          <Typography.Title level={1} className="ty-title">
            {data?.Title}
          </Typography.Title>
          {data.Descriptions && (
            <Typography.Title level={4} className="ty-sub">
              {data?.Descriptions}
            </Typography.Title>
          )}
          <Button onClick={goToProduct} className="app-button">
            {t("buyNow")}
          </Button>
        </div>
      )
    );
  };

  return (
    <Carousel
      fade
      autoplay
      autoplaySpeed={6000}
      speed={1000}
      className="app-carousel"
      pauseOnHover={false}
    >
      {carouselPicture.map((picture, index) => (
        <div key={index} className="carousel-item">
          <div className="overlay"></div>
          <img src={picture} alt="" />
          <CarouselContent />
        </div>
      ))}
    </Carousel>
  );
}
